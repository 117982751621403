// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Col from "../../../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../../../styleguide/components/Grid/Row/Row.res.js";
import * as Role from "../../../../../models/Role.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Select from "../../../../../styleguide/forms/Select/Select.res.js";
import * as Toggle from "../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Api_User from "../../../../../api/users/Api_User.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as UserEditFormCss from "./UserEditFormCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";

var validators_role = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.role.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.role
              };
      } else {
        return {
                TAG: "Error",
                _0: "Role is required"
              };
      }
    })
};

var validators = {
  role: validators_role,
  dailyLeads: undefined,
  signupDistribution: undefined
};

function initialFieldsStatuses(_input) {
  return {
          role: "Pristine",
          dailyLeads: "Pristine",
          signupDistribution: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            role: "Pristine",
            dailyLeads: "Pristine",
            signupDistribution: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.role;
  var tmp;
  tmp = typeof match !== "object" ? validators.role.validate(input) : match._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.dailyLeads
  };
  var match_0$1 = {
    TAG: "Ok",
    _0: input.signupDistribution
  };
  var roleResult = tmp;
  var roleResult$1;
  if (roleResult.TAG === "Ok") {
    var dailyLeadsResult = match_0;
    if (dailyLeadsResult.TAG === "Ok") {
      var signupDistributionResult = match_0$1;
      if (signupDistributionResult.TAG === "Ok") {
        return {
                TAG: "Valid",
                output: {
                  role: roleResult._0,
                  dailyLeads: dailyLeadsResult._0,
                  signupDistribution: signupDistributionResult._0
                },
                fieldsStatuses: {
                  role: {
                    TAG: "Dirty",
                    _0: roleResult,
                    _1: "Shown"
                  },
                  dailyLeads: {
                    TAG: "Dirty",
                    _0: dailyLeadsResult,
                    _1: "Hidden"
                  },
                  signupDistribution: {
                    TAG: "Dirty",
                    _0: signupDistributionResult,
                    _1: "Hidden"
                  }
                },
                collectionsStatuses: undefined
              };
      }
      roleResult$1 = roleResult;
    } else {
      roleResult$1 = roleResult;
    }
  } else {
    roleResult$1 = roleResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            role: {
              TAG: "Dirty",
              _0: roleResult$1,
              _1: "Shown"
            },
            dailyLeads: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            },
            signupDistribution: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurRoleField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.role, validators_role, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  role: status,
                                  dailyLeads: init.dailyLeads,
                                  signupDistribution: init.signupDistribution
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDailyLeadsField" :
                  var result$1 = Formality.validateFieldOnBlurWithoutValidator(state.input.dailyLeads, state.fieldsStatuses.dailyLeads, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  role: init.role,
                                  dailyLeads: status,
                                  signupDistribution: init.signupDistribution
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurSignupDistributionField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.signupDistribution, state.fieldsStatuses.signupDistribution, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  role: init.role,
                                  dailyLeads: init.dailyLeads,
                                  signupDistribution: status
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateRoleField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.role, state.submissionStatus, validators_role, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            role: status,
                                            dailyLeads: init.dailyLeads,
                                            signupDistribution: init.signupDistribution
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDailyLeadsField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$1.dailyLeads, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            role: init.role,
                                            dailyLeads: status,
                                            signupDistribution: init.signupDistribution
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateSignupDistributionField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.signupDistribution, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            role: init.role,
                                            dailyLeads: init.dailyLeads,
                                            signupDistribution: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                role: "Pristine",
                                dailyLeads: "Pristine",
                                signupDistribution: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                role: "Pristine",
                                dailyLeads: "Pristine",
                                signupDistribution: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateRole: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateRoleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDailyLeads: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDailyLeadsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateSignupDistribution: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateSignupDistributionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurRole: (function () {
              dispatch("BlurRoleField");
            }),
          blurDailyLeads: (function () {
              dispatch("BlurDailyLeadsField");
            }),
          blurSignupDistribution: (function () {
              dispatch("BlurSignupDistributionField");
            }),
          roleResult: Formality.exposeFieldResult(state.fieldsStatuses.role),
          dailyLeadsResult: Formality.exposeFieldResult(state.fieldsStatuses.dailyLeads),
          signupDistributionResult: Formality.exposeFieldResult(state.fieldsStatuses.signupDistribution),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.role;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.dailyLeads;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.signupDistribution;
              if (typeof tmp$2 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var UserEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var EditUsers = {};

function UserEditForm$1(props) {
  var currentUserId = props.currentUserId;
  var updateUsers = props.updateUsers;
  var users = props.users;
  var user = props.user;
  var initialInput = React.useMemo((function () {
          return {
                  role: user.role,
                  dailyLeads: user.dailyLeads,
                  signupDistribution: user.signupDistribution
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_User.update(user.id, {
                    role: output.role,
                    dailyLeads: output.dailyLeads,
                    signupDistribution: output.signupDistribution,
                    page: users.currentPage
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "UserEditForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "UserEditForm.make"
                              }, "UpdateUser::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  updateUsers(x._0);
                  cb.notifyOnSuccess({
                        role: output.role,
                        dailyLeads: output.dailyLeads,
                        signupDistribution: output.signupDistribution
                      });
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match = form.status;
  var tmp;
  tmp = typeof match !== "object" || match.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsxs(Row.make, {
          className: "row",
          children: [
            JsxRuntime.jsx(Col.make, {
                  md: 3,
                  lg: 3,
                  children: JsxRuntime.jsx("div", {})
                }),
            JsxRuntime.jsx(Col.make, {
                  md: 9,
                  lg: 9,
                  children: JsxRuntime.jsx(ErrorMessage.make, {
                        children: "Something went wrong."
                      })
                })
          ]
        });
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "user-form--user-role",
                              className: Cx.cx([Caml_obj.notequal(currentUserId, user.id) ? UserEditFormCss.roleLabel : ""]),
                              children: "Role"
                            }),
                        Caml_obj.notequal(currentUserId, user.id) ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsxs(Select.make, {
                                        id: "user-form--user-role",
                                        value: form.input.role,
                                        onChange: (function ($$event) {
                                            form.updateRole((function (input, value) {
                                                    return {
                                                            role: value,
                                                            dailyLeads: input.dailyLeads,
                                                            signupDistribution: input.signupDistribution
                                                          };
                                                  }), $$event.target.value);
                                            form.submit();
                                          }),
                                        children: [
                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                value: "user",
                                                children: "User"
                                              }, "user-role-user"),
                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                value: "concierge",
                                                children: "Concierge"
                                              }, "user-role-concierge"),
                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                value: "provider",
                                                children: "Provider"
                                              }, "user-role-provider"),
                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                value: "agent",
                                                children: "Agent"
                                              }, "user-role-agent"),
                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                value: "admin",
                                                children: "Admin"
                                              }, "user-role-admin")
                                        ]
                                      }),
                                  fieldError(form.roleResult)
                                ]
                              }) : JsxRuntime.jsx("span", {
                                children: Role.toCapital(Role.fromString(form.input.role))
                              })
                      ],
                      className: Cx.cx([
                            UserEditFormCss.field,
                            Caml_obj.equal(currentUserId, user.id) ? UserEditFormCss.noDropdown : ""
                          ])
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "user-form--user-daily",
                              children: "Daily Leads"
                            }),
                        JsxRuntime.jsx(Toggle.make, {
                              on: form.input.dailyLeads,
                              size: "MD",
                              onChange: (function (_event) {
                                  form.updateDailyLeads((function (input, value) {
                                          return {
                                                  role: input.role,
                                                  dailyLeads: value,
                                                  signupDistribution: input.signupDistribution
                                                };
                                        }), !form.input.dailyLeads);
                                  form.submit();
                                })
                            })
                      ],
                      className: UserEditFormCss.fieldToggle
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "user-form--user-signup",
                              children: "Signup Leads"
                            }),
                        JsxRuntime.jsx(Toggle.make, {
                              on: form.input.signupDistribution,
                              size: "MD",
                              onChange: (function (_event) {
                                  form.updateSignupDistribution((function (input, value) {
                                          return {
                                                  role: input.role,
                                                  dailyLeads: input.dailyLeads,
                                                  signupDistribution: value
                                                };
                                        }), !form.input.signupDistribution);
                                  form.submit();
                                })
                            })
                      ],
                      className: UserEditFormCss.fieldToggle
                    }),
                tmp
              ],
              className: UserEditFormCss.form,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var Css;

var make = UserEditForm$1;

export {
  Css ,
  UserEditForm ,
  EditUsers ,
  make ,
}
/* Col Not a pure module */
