// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var form = Cx.cx([
      "flex",
      "flex-col",
      "gap-1.5",
      "mt-6"
    ]);

var field = Cx.cx([
      "flex",
      "flex-col",
      "mb-5"
    ]);

var noDropdown = Cx.cx([
      "flex-row",
      "justify-between"
    ]);

var fieldToggle = Cx.cx([
      "flex",
      "flex-row",
      "justify-between",
      "mb-5"
    ]);

var buttonHiddenContainer = Cx.cx([
      "flex",
      "flex-col",
      "items-center",
      "justify-center",
      "gap-2.5",
      "md:hidden",
      "lg:hidden"
    ]);

var roleLabel = "mb-2.5";

export {
  form ,
  roleLabel ,
  field ,
  noDropdown ,
  fieldToggle ,
  buttonHiddenContainer ,
}
/* form Not a pure module */
