// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var totalUsers = Cx.cx([
      "text-gray-300",
      "pl-2.5",
      "font-normal"
    ]);

var userLinks = Cx.cx([
      "flex",
      "gap-2",
      "text-teal-700",
      "font-medium"
    ]);

var userLink = Cx.cx([
      "text-teal",
      "hover:text-purple",
      "cursor-pointer",
      "font-medium"
    ]);

export {
  totalUsers ,
  userLinks ,
  userLink ,
}
/* totalUsers Not a pure module */
