// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var sideModalContainer = Cx.cx(["z-1010"]);

var sideModal = Cx.cx([
      "fixed",
      "top-1/2",
      "right-0",
      "-translate-y-1/2",
      "-top-50",
      "-right-0",
      "h-full",
      "w-120",
      "max-w-full",
      "z-1010",
      "flex",
      "bg-white"
    ]);

var modalContents = Cx.cx([
      "absolute",
      "top-0",
      "left-0",
      "w-full",
      "h-full",
      "p-10",
      "overflow-auto"
    ]);

var modalOverlay = Cx.cx([
      "z-1000",
      "fixed",
      "top-0",
      "left-0",
      "w-full",
      "h-full",
      "bg-black/60"
    ]);

var closeOverlayButton = Cx.cx([
      "absolute",
      "top-5",
      "right-5"
    ]);

var title = Cx.cx([
      "text-2xl",
      "font-semibold"
    ]);

var subtitle = Cx.cx([
      "text-gray-700",
      "text-base",
      "mb-3"
    ]);

export {
  sideModalContainer ,
  sideModal ,
  modalContents ,
  modalOverlay ,
  closeOverlayButton ,
  title ,
  subtitle ,
}
/* sideModalContainer Not a pure module */
